import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Button from "../components/button"
import "../main.css"

const ThankYouPage = () => (
  <section id="thankyou">
    <Link to="/">
      <StaticImage
        src="../images/logo.png"
        width={200}
        quality={100}
        formats={["auto", "webp", "avif"]}
        alt="Life-tuning logo"
        placeholder="blurred"
      />
    </Link>
    <p>Thank you for your submission!</p>
    <Button style={{ marginRight: "auto" }} accent={true} to="/contact">
      Go back to our site
    </Button>
  </section>
)

export default ThankYouPage
